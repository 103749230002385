<template>
  <div id="app">
    <!-- 路由占位符-我是标记1111 -->
    <router-view></router-view>
  </div>
</template>

<script>
// import DevicePixelRatio from "./utils/devicePixelRatio";
import autofit from 'autofit.js';

export default {
  name: "App",
  components: {},
  // created() {
  //   new DevicePixelRatio().init();
  // },
  mounted() {
    // 屏幕自适应
    // autofit.init({
    //     dh: 900,
    //     dw: 1520,
    //     el:"#app",
    //     resize: true
    //   },false);
    window.addEventListener("unload", this.saveState);
  },
  methods: {
    saveState() {
      localStorage.setItem("state", JSON.stringify(this.$store.state));
      autofit.off()
    },
  },
};
</script>

<style>
@import url("./assets/css/global.css");


::-webkit-scrollbar {
  width: 4px;
}

/* //滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #b8bac0ec;
  border-radius: 3px;
}


:deep .el-popup-parent--hidden .v-modal {
  z-index: 0;
  position: none;
  width: 0;
  height: 0;
  opacity: 0;
  background: none;
}

</style>
