<template>
  <div id="navbar">

    <div class="login-container">
      <div class="login-box">
        <el-tabs v-model="activeName" stretch>
          <el-tab-pane label="验证码登录" name="first">
            <el-form ref="loginForm1" :model="loginData" :rules="loginFornRules" class="login-form" autocomplete="on"
                     label-position="left">
              <el-form-item prop="phone">
                <el-input ref="phone" v-model="loginData.phone" placeholder="手机号" name="phone" type="text"
                          prefix-icon="el-icon-phone"/>
              </el-form-item>

              <el-form-item>
                <el-input v-model="loginData.code" placeholder="验证码" prefix-icon="el-icon-key"
                          @keyup.enter.native="handleLogin1">
                  <el-button slot="append" @click="getSms" :disabled="disabled">{{ send }}</el-button>
                </el-input>
              </el-form-item>

              <el-button class="button" :loading="loading" type="primary" style="width: 100%; margin-bottom: 1.275rem"
                         @click.native.prevent="handleLogin1">登录
              </el-button>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="账号登录" name="second">
            <el-form ref="loginForm" :model="loginForm" :rules="loginFornRules" class="login-form" auto-complete="on"
                     label-position="left">
              <el-form-item prop="username">
                <el-input ref="username" v-model="loginForm.username" placeholder="账号" name="username" type="text"
                          prefix-icon="el-icon-user-solid" :validate-event="false"/>
              </el-form-item>
              <el-form-item prop="password">
                <el-input ref="password" :type="flagType" v-model="loginForm.password" placeholder="密码"
                          name="password"
                          prefix-icon="el-icon-lock" :validate-event="false" @keyup.enter.native="handleLogin">
                  <i slot="suffix" :class="[
                    this.flag
                      ? 'iconfont icon-zhengyan'
                      : 'iconfont icon-biyan',
                  ]" style="margin-top: 8px; font-size: 1.2625rem;line-height: 3rem;" @click="getFlag()"/>
                </el-input>
              </el-form-item>

              <el-button class="button" :loading="loading" type="primary" style="width: 100%; margin-bottom: 1.275rem"
                         @click.native.prevent="handleLogin">登录
              </el-button>
            </el-form>
          </el-tab-pane>

        </el-tabs>

      </div>
    </div>

    <div class="mark-box"></div>
  </div>
</template>

<script>
import {LoginApi, getSms, LoginPhoneApi} from "@/api/user.js";
import {setCookie} from "@/utils/auth.js";

export default {
  components: {},
  data() {
    return {
      activeName: "first",
      // 账号密码
      loginForm: {
        username: "",
        password: "",
      },
      //密码小眼睛切换状态
      flag: false,
      flagType: "password",
      // 手机号验证码
      loginData: {
        phone: "",
        code: "",
      },
      send: "获取验证码",
      disabled: false,
      // 表单验证规则
      loginFornRules: {
        // 验证用户名是否合法
        username: [{required: true, message: "请输入用户名称"}],
        // 验证密码是否合法
        password: [{required: true, message: "请输入密码"}],
        phone: [
          {
            required: true,
            message: "请输入合法手机号",
            trigger: "change",
          },
          {
            pattern: /^1[3|5|7|8|9]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      redirect: undefined,
    };
  },
  //方法集合
  methods: {
    //小眼睛切换状态
    getFlag() {
      this.flag = !this.flag;
      this.flagType = this.flag ? "text" : "password"; //text为显示密码；password为隐藏密码
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          LoginApi(this.loginForm)
              .then((res) => {
                this.loading = false;
                if (res.data && res.data.resultInfo) {
                  if (res.data.resultInfo.code === 200) {
                    this.handleSuccessfulLogin(res.data);
                  } else {
                    this.$message.error(res.data.resultInfo.msg || '登录失败');
                  }
                } else {
                  console.error('Unexpected response structure:', res);
                  this.$message.error('登录失败：服务器响应格式错误');
                }
              })
              .catch((err) => {
                this.loading = false;
                console.error('Login error:', err);
                this.$message.error(err.response?.data?.message || '登录失败，请稍后重试');
              });
        } else {
          this.loading = false;
        }
      });
    },

    handleSuccessfulLogin(data) {
      setCookie("token", data.tokenMap?.token);
      this.$store.commit("changemenusDatas", data.menuList || []);
      this.$message.success({
        message: '登录成功',
        duration: 700
      });
      this.$router.push("/home");
    },
    // 获取验证码
    getSms() {
      const self = this;
      getSms({phone: this.loginData.phone}).then((res) => {
        if (res.data.code == 200) {
          self.$message.success(res.data.msg || res.data.response.msg);
          self.disabled = true;
          let time = 60;
          let timer = setInterval(fun, 1000);

          function fun() {
            time--;
            if (time >= 0) {
              self.send = time + "s后刷新";
            } else if (time < 0) {
              self.send = "获取验证码";
              self.disabled = false;
              clearInterval(timer);
              time = 60;
            }
          }
        } else {
          console.error('判断异常:', res);

          self.$message.error(res.data.msg || res.data.response.msg);
        }
      }).catch((err) => {
        console.error('捕获异常:', err);
        this.$message.error(err.response?.data?.msg || '获取验证码失败，请稍后重试');
        // this.$message.error(err.response.data.msg);
      });
    },
    handleLogin1() {
      this.$refs.loginForm1.validate((valid) => {
        if (valid) {
          this.loading = true;
          LoginPhoneApi(this.loginData)
              .then((res) => {
                this.loading = false;
                if (res.data && res.data.resultInfo) {
                  if (res.data.resultInfo.code === 200) {
                    setCookie("token", res.data.tokenMap?.token);
                    this.$store.commit("changemenusDatas", res.data.menuList || []);
                    this.$message.success("登录成功");
                    this.$router.push("/home");
                  } else {
                    this.$message.error(res.data.resultInfo.msg || '登录失败');
                  }
                } else {
                  console.error('Unexpected response structure:', res);
                  this.$message.error('登录失败：服务器响应格式错误');
                }
              })
              .catch((err) => {
                this.loading = false;
                console.error('Login error:', err);
                this.$message.error(err.response?.data?.message || '登录失败，请稍后重试');
              });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
  },
  mounted() {
    // this.Adaptive();
    const dayOfWeek = new Date().getDay();
    // const dayOfWeek = 6;
    const loginBackground = document.getElementById('navbar');
    switch (dayOfWeek) {
      case 0:
        loginBackground.style.backgroundImage = "url('https://lrass.oss-cn-beijing.aliyuncs.com/kehu/%E9%B8%9F.jpg')";
        break;
      case 1:
        loginBackground.style.backgroundImage = "url('https://lrass.oss-cn-beijing.aliyuncs.com/kehu/%E5%86%AC%E5%A4%A9.jpg')";
        break;
      case 2:
        loginBackground.style.backgroundImage = "url('https://lrass.oss-cn-beijing.aliyuncs.com/kehu/%E9%A3%9E%E9%B8%9F.jpg')";
        break;
      case 3:
        loginBackground.style.backgroundImage = "url('https://lrass.oss-cn-beijing.aliyuncs.com/kehu/%E6%A2%85%E8%8A%B1.jpg')";
        break;
      case 4:
        loginBackground.style.backgroundImage = "url('https://lrass.oss-cn-beijing.aliyuncs.com/kehu/%E8%8A%B1.jpg')";
        break;
      case 5:
        loginBackground.style.backgroundImage = "url('https://lrass.oss-cn-beijing.aliyuncs.com/kehu/%E8%8F%8A%E8%8A%B1.jpg')";
        break;
      case 6:
        loginBackground.style.backgroundImage = "url('https://lrass.oss-cn-beijing.aliyuncs.com/kehu/%E5%B2%A9%E7%9F%B3.jpg')";
        break;
      default: // 默认情况
        loginBackground.style.backgroundImage = "url('https://lrass.oss-cn-beijing.aliyuncs.com/kehu/%E9%B8%9F.jpg')";
        break;
    }
  }
};
</script>

<style lang="less" scoped>

/* General styles */


/* Login container styles */
#navbar {
  width: 100%;
  height: 100vh;
  //background: url(http://xinmaoyixue.cn/C端/flower-5459972.jpg);
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.login-container {
  /* background: url('../assets/20230907-092334.png') no-repeat center center fixed; */
  width: 255px;
  padding: 2.1875rem 1.5625rem 1.875rem 1.5625rem;
  background-color: rgba(255, 255, 255, 0.8);
  // box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border: 1px solid rgba(12, 12, 12, 0.1); /* 修改边框的颜色和透明度 */
  border-radius: 1.0625rem;
  // margin: 10em auto; /* 添加这一行来实现水平居中 */
  margin: 21.25rem 78.75rem;
  // z-index: 99;
}

.login-box {
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 1.25rem;
  }
}

/deep/ .el-tabs__header {
  margin: 0 0 30px;
}

.el-form-item {
  margin-bottom: 30px;
}


/* Social media button styles */
.social-media-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.facebook-button, .github-button, .google-button {
  border: none;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.facebook-button {
  background-color: #3b5998;
}

.github-button {
  background-color: #333;
}

.google-button {
  background-color: #db4437;
}

/* Form styles */
.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 15px;

}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Makes sure the padding doesn't affect the overall width */
}

.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.remember-me input {
  margin-right: 5px;
}

.sign-in-button {
  background-color: #e91e63;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sign-in-button:hover {
  background-color: #c2185b;
}

/* Footer styles */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  // padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.footer p {
  color: #fff;
}

.footer span {
  color: red;
}

// .mark-box{
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   // z-index: 1;
//   background-color: rgba(0,0,0,0.4);
// }

/* Add responsive design if needed */
@media (max-width: 600px) {
  // .login-container {
  //   width: 100%;
  //   box-shadow: none;
  //   border-radius: 0;
  // }
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../assets/6688.png') no-repeat center center fixed;
  background-size: 100% 100%;
}
</style>