import Vue from 'vue'
import App from './App.vue'
import router from "./router/router"
import store from './store'
import "./plugins/element"
// 导入全局样式表
import "./assets/css/global.css"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'default-passive-events'
Vue.use(ElementUI)

// 导入字体图标
import "@/assets/fonts/iconfont.css"
Vue.config.productionTip = false
import Tui from "@wocwin/t-ui"
Vue.use(Tui)
import './utils/rem' //px转rem
import VueDraggableResizable from 'vue-draggable-resizable'

// 可选择导入默认样式
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

Vue.component('vue-draggable-resizable', VueDraggableResizable)

//按钮防抖事件：当第一次点击按钮时，设置它的 disabled 属性，超过防抖时间后释放属性
Vue.directive('debounce', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 2000)
      console.log("成功成功成功")
    })
  }
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')