import Cookies from "js-cookie";

// 设置cookie
export let setCookie = (key, value) => {
    Cookies.set(key, value, {
        expires: 1
    })
}
// 获取cookie
export let getCookie = (key) => {
    return Cookies.get(key)
}
// 删除cookie
export function removeToken(key) {
    return Cookies.remove(key) // 根据存储的名称进行删除
}