import axios from 'axios'
import {
    getCookie
} from "@/utils/auth.js"
const service = axios.create({
    baseURL: '/api', // api 的 base_url 后端地址
    timeout: 8000, // request timeout
})
// axios请求拦截器
service.interceptors.request.use(config => {
    var token = getCookie("token");
    config.headers.Authorization = 'Bearer ' + token;
    return config
})

export default service