<!-- 主页 -->
<template>
  <el-container class="home-container">
    <!-- 页面主体区域 -->
    <el-container class="aside-container">
      <el-container>
        <el-header>
          <div class="top-menu">
            <div class="left-box">
              <div class="left-box-center">
                <img src="../assets/LOGO图标.png" alt="" />
                <span>RASS Science Lab</span>
              </div>
            </div>
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo center-box"
              mode="horizontal"
              :router="true"
              menu-trigger="click"
              text-color="#484849"
              active-text-color="#409eff"
            >
              <el-menu-item index="/dashboard">
                <span slot="title" :to="{ path: '/home' }">首页</span>
              </el-menu-item>
              <template v-for="(item, index) in menus">
                <el-menu-item :index="item.path">
                  <span slot="title">{{ item.name }}</span>
                </el-menu-item>
                <!-- 控制菜单权限 -->
                <!-- <el-submenu :index="item.path" v-if="!item.hidden" :key="index">
                  <template slot="title">
                    <span>{{ item.name }}</span>
                  </template>
                  <el-menu-item-group v-for="(chil, index) in item.children" :key="index">
                    <el-menu-item :index="chil.path">
                      {{ chil.name }}
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu> -->
              </template>
            </el-menu>
            <div class="right-box">
              <span>姓名： {{ name }}</span>
              <span>账号： {{ userID }}</span>
              <el-button
                type="danger"
                size="mini"
                class="logout-btn"
                @click="detailout"
                >修改密码</el-button
              >
              <el-button
                type="danger"
                size="mini"
                class="logout-btn"
                @click="logout"
                >退出</el-button
              >
            </div>
          </div>
        </el-header>

        <el-container class="main-container">
          <!-- 右侧内容主体 -->
          <el-main>
            <transition :name="transitionName">
              <keep-alive include="Project,Individual,pool,Contract">
                <router-view class="transitionBody"></router-view>
              </keep-alive>
            </transition>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import { getUserInfo } from '@/api/user.js'
import { removeToken } from '@/utils/auth.js'
export default {
  components: {},
  data() {
    return {
      name: '',
      userID: '',
      activeName: '',
      // 左侧菜单数据
      menus: [],
      activePath: '/home', //被激活的地址
      activeIndex: '/home',
      transitionName: 'transitionLeft',
      total: 0, // 消息数量
      tb: 0,
      tbs: 0,
      messageList: [],
      messageLists: [],
    }
  },
  methods: {
    logout() {
      this.$confirm('是否确认退出系统?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          removeToken('token')
          this.$router.push('/login')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出',
          })
        })
    },
    detailout() {
      this.$router.push('/sysset')
    },
    // 点击1
    clickUnread() {
      this.$router.push({
        path: '/myunread',
      })
      window.location.reload()
    },
    // 点击2
    clickUnreads() {
      this.$router.push({
        path: '/myunread',
        query: {
          activeName: 'second',
        },
      })
      window.location.reload()
    },
  },
  created() {
    getUserInfo().then((res) => {
      this.userID = res.data.result.username
      this.name = res.data.result.realname
      sessionStorage.setItem('id', res.data.result.id)
      sessionStorage.setItem('roleName', res.data.result.roleName)
    })
    // this.checkForNewMessages(); // 在created钩子中调用该方法
    // setInterval(() => {
    //   this.checkForNewMessages();
    // }, 1200000);  // 每隔20分钟调用一次该方法
    this.activeName = this.$route.query.activeName || 'first'
  },
  mounted() {
    this.menus = this.$store.state.menus
    console.log('this.menus', this.menus)
  },
  watch: {
    $route: {
      handler(newVal) {
        this.activePath = newVal.path
        this.activeIndex = newVal.path
      },
      deep: true,
      immediate: true,
    },
    '$store.state.menus': {
      handler(newVal, oldVal) {
        this.menus = newVal
      },
      deep: true,
    },
  },
}
</script>
<style lang="less" scoped>
.mr-5 {
  margin-right: 5px;
}
.ml-25 {
  display: flxe;
  text-align: center;
  vertical-align: center;
}
.home-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .aside-container {
    // height:100%;
  }

  // .header-top {
  //   background-color: #fff;
  //   display: flex;
  //   width: 100%;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 0;
  //   color: #484849;
  //   font-size: 20px;
  //   // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  //   border-bottom: 1px solid #ececf8;

  // }

  // .header-height {
  //   width: 100%;
  //   height: 60Px;
  //   display: flex;
  //   justify-content: space-between;
  //   padding: 0;
  //   .div {
  //     display: flex;
  //     align-items: center;
  //   }

  //   .textsize {
  //     font-size: 14px;
  //     span{
  //       margin-right: 20px;
  //     }
  //   }

  //   .logout-btn {
  //     margin-right: 20px;
  //   }
  // }
  .top-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-box {
      text-align: left;

      .left-box-center {
        // height: 60px;
        width: 222px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        z-index: 99;
        span {
          font-size: 19px;
        }
      }

      img {
        width: 35px;
        height: 35px;
        margin: 0 10px 0 0;
        margin-left: 10px;
      }
    }
    .center-box {
      text-align: center;
      span {
        font-size: 16px;
        // font-weight: 600;
      }
    }
    .right-box {
      text-align: right;
      margin-right: 10px;
      span {
        margin-right: 20px;
        font-size: 14px;
      }
    }
  }
  // .header-bread {
  //   width: 50%;
  //   display: flex;
  //   height: 60Px;
  //   align-items: center;
  //   background: #fff;
  //   // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  //   i {
  //     margin: 0 20px 0 15px;
  //     font-size: 20px;
  //     color: #000;
  //   }

  //   .el-breadcrumb {
  //     margin-bottom: 0;
  //     font-size: 15px !important;
  //   }
  // }

  // .el-menu-demo{
  //   margin-left: 530px;
  //   }

  // .el-aside {
  //   background-color: #fff;
  //   height: 100%;
  //   position: relative;

  //   .el-submenu .el-menu-item {
  //     min-width: 100px;
  //   }

  //   /deep/.el-menu-item-group__title {
  //     padding: 2px 0 0px 0px;
  //   }

  //   .el-menu {
  //     border-right: 0;

  //     .el-submenu__title i {
  //       margin-right: 15px;
  //     }

  //     .iconfont {
  //       margin-right: 15px;
  //     }

  //     .el-menu-item.is-active {
  //       //设置字体颜色
  //       color: #409eff !important;
  //     }
  //   }
  // }

  // .main-container {
  //   height: 100%;
  // }

  .el-main {
    background-color: #eaedf1;
    padding: 15px;
  }
}

// .transitionBody {
//   transition: all 0.4s ease-in-out;
// }

// .transitionLeft-enter,
// .transitionRight-leave-active {
//   -webkit-transform: translate(100%, 0);
//   transform: translate(100%, 0);
// }

// .transitionLeft-leave-active,
// .transitionRight-enter {
//   -webkit-transform: translate(-100%, 0);
//   transform: translate(-100%, 0);
// }

.my-button {
  border: none;
  padding: 0 10px;
  width: 95%;
  height: 30px;
}

.my-button:hover {
  background-color: #f5f7fa;
}
</style>
