import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : {
        menus: [],
    },
    getters: {},
    mutations: {
        changemenusDatas(state, data) {
            state.menus = data
        }
    },
    actions: {},
    modules: {}
})