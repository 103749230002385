// 引入axios文件
import service from "../utils/request.js"

// 登录
export function LoginApi(data) {
    return service({
        url: '/login',
        method: 'post',
        data
    })
}

// 根据手机号码登录
export function LoginPhoneApi(data) {
    return service({
        url: '/loginByPhone',
        method: 'post',
        data
    })
}

// 获取登录用户信息
export function getUserInfo(params) {
    return service({
        url: '/getLoginUserInfo',
        method: 'get',
        params
    })
}

//获取验证码
export function getSms(params) {
    return service({
        url: '/sendSms',
        method: 'get',
        // contentType: 'application/json',
        params
    })
}