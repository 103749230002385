import Vue from "vue"
import Router from "vue-router"

import Login from "../components/login.vue"
import Home from "../components/layout.vue"
import {
    getCookie
} from "@/utils/auth.js"

Vue.use(Router)
const router = new Router({
    routes: [{
        path: "/",
        redirect: "/dashboard",
        hidden: true,
    },
    {
        path: "/login",
        component: Login,
        hidden: true,
    },
    {
        path: "/home",
        component: Home,
        redirect: '/dashboard',
        name: "首页",
        hidden: true,
        children: [{
            path: '/dashboard',
            component: () => import('@/views/dashboard/DashBoard.vue'),
        }, ]
    },
        {
            path: "/schedule",
            component: Home,
            name: "日程管理",
            redirect: '/rcinfo',
            icon: 'iconfont icon-richeng',
            children: [{
                path: '/rcinfo',
                component: () => import('@/views/schedule/ScheduleInformation.vue'),
                name: "日程信息"
            }, ]
        },
        {
            path: "/controller",
            component: Home,
            name: "项目模版",
            redirect: '/templates',
            icon: 'iconfont icon-richeng',
            children: [{
                path: '/templates',
                component: () => import('@/views/controller/templates.vue'),
            }, 
            {
                path: '/templatetask',
                component: () => import('@/views/controller/TemplatetaskDetail.vue'),
                name: "项目模版详情"
            },]
        },
        {
            path: "/scientisearch",
            component: Home,
            name: "科研数据",
            redirect: '/scisearch',
            icon: 'iconfont icon-richeng',
            children: [{
                path: '/scisearch',
                component: () => import('@/views/scientisearch/scisearch.vue'),
            }, 
            {
                path: '/scisearchtask',
                component: () => import('@/views/scientisearch/ScisearchDetail.vue'),
                name: "科研数据详情"
            },]
            
        },
        {
            path: "/system",
            component: Home,
            name: "账号管理",
            redirect: '/peoples',
            icon: 'iconfont icon-richeng',
            children: [{
                path: '/peoples',
                component: () => import('@/views/system/Peoplemm.vue'),
            }, ]
        },
        
        {
            path: '/system',
            component: Home,
            redirect: '/people',
            name: '系统管理',
            icon: 'iconfont icon-xitongfuwu',
            children: [{
                    path: '/people',
                    component: () => import('@/views/system/Peoplemm.vue'),
                    name: '人员管理',
                },
                {
                    path: '/information',
                    component: () => import('@/views/system/InformationManagement.vue'),
                    name: "信息管理"
                },
                {
                    path: '/role',
                    component: () => import('@/views/system/RoleView.vue'),
                    name: '角色管理',
                },
                {
                    path: '/jurisdiction',
                    component: () => import('@/views/system/Jurisdiction.vue'),
                    name: '权限管理',
                },
                {
                    path: '/parame',
                    component: () => import('@/views/system/Parameters.vue'),
                    name: '参数字典',
                },
                {
                    path: '/sysset',
                    component: () => import('@/views/system/Syssetup.vue'),
                    name: '系统设置',
                }
            ]
        },
        {
            path: '*',
            name: 'not',
            hidden: true,
            component: () => import('../components/NotView.vue')
        },
    ]
})
// 挂载路由导航首位
router.beforeEach((to, from, next) => {
    // to 将要访问的路径
    // from 代表从哪个路径跳转而来
    // next 是一个函数,表示放行
    // next() 方形 next('/login') 强制跳转
    if (to.path === '/login') return next()
    const tokenStr = getCookie("token")
    if (!tokenStr) return next('/login')
    next()
})

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};


// router.beforeEach((to , from , next) => {
//     if(store.state.sidebar.userRouter.length === 0){
//         // 无菜单时拉取
//         getCookie()
//             .then(res => {
//                 let _menu = res.data.Data.ColumnDataList || [];
//                 // 整理菜单&路由数据
//                 store.commit("setMenuRouter", _menu);
//                 // 推入权限路由列表
//                 router.addRoutes(store.state.sidebar.userRouter);
//                 next({...to, replace: true });
//             })
//             .catch( err => {
//                 console.log(err);
//                 Message.error("服务器连接失败");
//             })
//     } else {
//         //当有用户权限的时候，说明所有可访问路由已生成 如访问没权限的菜单会自动进入404页面
//         if(to.path == "/login"){
//             next({
//                 name:'index'
//             })
//         }else {
//             next();
//         }
//     }
// })
// router.beforeEach((to, from, next) => {
//     // 判断当前用户是否已拉取权限菜单
//     if (store.state.sidebar.userRouter.length === 0) {
//       // 无菜单时拉取
//       getMenuRouter()
//         .then(res => {
//           let _menu = res.data.Data.ColumnDataList || [];
//           // if (res.data.Data.ColumnDataList.length > 0) {
//           // 整理菜单&路由数据
//           store.commit("setMenuRouter", _menu);
//           // 推入权限路由列表
//           router.addRoutes(store.state.sidebar.userRouter);
//           next({...to, replace: true });
//           // }
//         })
//         .catch(err => {
//           // console.log(err);
//           // Message.error("服务器连接失败");
//         });
//     } else {
//       //当有用户权限的时候，说明所有可访问路由已生成 如访问没权限的菜单会自动进入404页面
//       if (to.path == "/login") {
//         next({
//           name: "index"
//         });
//       } else {
//         next();
//       }
//     }
//   } else {
//     // 无登录状态时重定向至登录 或可进入无需登录状态路径
//     if (to.path == "/login" || to.meta.auth === 0) {
//       next();
//     } else {
//       next({
//         path: "/login"
//       });
//     }
//   }
// })


export default router